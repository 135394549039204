import { clusterApiUrl } from "@solana/web3.js";

// export const SERVER_URL = 'http://localhost:4000';
export const SERVER_URL = "https://backend.tars.gg";

// export const SOLANA_RPC = "http://127.0.0.1:8899";
// solana config 3 places
// helius rpc
// export const SOLANA_RPC = 'https://mainnet.helius-rpc.com/?api-key=571874a6-e07b-4be4-8296-e7329c31cc66'
// export const SOLANA_RPC = clusterApiUrl("devnet")
// export const SOLANA_RPC = 'https://rpc.ankr.com/solana'
// export const SOLANA_RPC = 'https://mainnet.helius-rpc.com/?api-key=9fff21f6-b15c-4d4a-97bf-b308b83439c5'
export const SOLANA_RPC = 'https://newest-summer-sheet.solana-mainnet.quiknode.pro/35eee70e3ee0f5256d8da49e7351ee71d48fb70c'
// export const SOLANA_RPC = 't-66e6b5771808051b636f5eb0-1a930bafb68e4f86b1c109b9'

export const shortenSolanaAddress = (
  address: string,
  startLength = 4,
  endLength = 4
) => {
  if (!address) return "";

  const start = address.slice(0, startLength);
  const end = address.slice(-endLength);
  return `${start}...${end}`;
};
